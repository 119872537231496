import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import UserProvider from './providers/user';

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <UserProvider>
        <Router />
      </UserProvider>
    </ThemeConfig>
  );
}
