import { createContext, useEffect, useState } from 'react';
import { auth, generateUserDocument } from '../firebase';

export const userContext = createContext({ user: null });

const UserProvider = ({ children }) => {
  const [state, setState] = useState(
    localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
  );

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const user = await generateUserDocument(userAuth);
        setState({ user });
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        setState({});
        localStorage.removeItem('user');
      }
    });
  }, []);

  return <userContext.Provider value={state.user}>{children}</userContext.Provider>;
};

export default UserProvider;
