import { useState, useEffect } from 'react';
import moment from 'moment';

// material
import { DataGrid } from '@material-ui/data-grid';
import { Box, Grid, Container, Typography, LinearProgress } from '@material-ui/core';

// components
import { firestore } from '../firebase';
import Page from '../components/Page';
import Summary from '../containers/omie/Summary';

// ----------------------------------------------------------------------

export default function Pagarme() {
  const transactionsCollections = firestore.collection('pagarme_transactions');
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const totalWithError = transactions.filter(
    (transaction) => transaction.omie_processed?.case === 'Error'
  ).length;

  const totalPipedriveSuccess = transactions.filter(
    (transaction) => !!transaction.omie_processed
  ).length;

  const totalNotSync =
    totalPipedriveSuccess.length -
    transactions.filter((transaction) => transaction.omie_processed).length;

  const totalTransactions = transactions.length;

  useEffect(() => {
    transactionsCollections
      .orderBy('date_updated', 'desc')
      .get()
      .then((snapshot) => {
        const transactionsRaw = snapshot.docs
          .map((doc) => doc.data())
          .filter(
            (transaction) =>
              transaction.omie_processed !== undefined && transaction.omie_processed !== null
          );
        setTransactions(transactionsRaw);
      })
      .finally(() => setIsLoading(false));
  }, [transactionsCollections]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'date', headerName: 'Data', width: 120, sortable: false },
    { field: 'customer', headerName: 'Cliente', width: 300, sortable: false },
    { field: 'email', headerName: 'E-mail', width: 300, sortable: true },
    {
      field: 'product',
      headerName: 'Produto',
      sortable: false,
      width: 200
    },
    {
      field: 'paid',
      headerName: 'Valor',
      width: 160,
      sortable: true
    },
    { field: 'omie', headerName: 'Ação', width: 200, sortable: false }
  ];

  const rows = transactions
    .filter((transaction) => transaction.pipedrive_processed)
    .map((transaction, index) => {
      const { customer } = transaction;
      const date = moment(transaction.date_updated).format('D/MM/YY');
      const product = transaction.product?.name;
      const paid = `R$ ${parseFloat(transaction.paid).toFixed(2)}`;
      const omie = transaction.omie?.case;

      return {
        id: index + 1,
        date,
        customer: customer.name,
        email: customer.email,
        product,
        paid,
        omie
      };
    });

  return (
    <Page title="Ordem de seriviços | Omie">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" style={{ marginBottom: '10px' }}>
            Transações registrados na Omie
          </Typography>
          {isLoading && <LinearProgress />}
        </Box>

        <Grid container spacing={3}>
          <Summary
            {...{
              totalPipedriveSuccess,
              totalNotSync,
              totalWithError,
              totalTransactions,
              isLoading
            }}
          />

          <Grid item xs={12}>
            <div style={{ height: 800, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} pageSize={50} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
