import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
// material
import { Stack, Button, Divider, Typography } from '@material-ui/core';

import { signInWithGoogle } from '../../firebase';

// ----------------------------------------------------------------------

export default function AuthSocial() {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={signInWithGoogle}
        >
          <Icon icon={googleFill} color="#DF3E30" height={24} />
          <span style={{ padding: '0 10px' }}>Google</span>
        </Button>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          ou
        </Typography>
      </Divider>
    </>
  );
}
