import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { userContext } from './providers/user';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/Page404';

import Omie from './pages/Omie';
import Pipedrive from './pages/Pipedrive';
import PagarmeBlacklist from './pages/PagarmeBlacklist';

// ----------------------------------------------------------------------

export default function Router() {
  const user = useContext(userContext);

  return useRoutes([
    {
      path: '/dashboard',
      element: user?.uid ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/pipedrive', element: <Pipedrive /> },
        { path: '/omie', element: <Omie /> },
        { path: '/pagarme/backlist', element: <PagarmeBlacklist /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
