import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();

  return (
    <>
      <Box component="img" src="/static/logo.png" sx={{ width: 40, height: 40, ...sx }} />
      <Typography
        variant="h4"
        style={{ margin: '3px 0 0 10px' }}
        color={theme.palette.text.primary}
      >
        Escola EDTI
      </Typography>
    </>
  );
}
