import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import iconTrasactions from '@iconify/icons-ant-design/unordered-list';

import SummaryCard from './SummaryCard';

const Summary = ({
  totalTransactions,
  totalPipedriveSuccess,
  totalNotSync,
  totalWithErros,
  isLoading,
  onSummaryClick
}) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          text="Total de transações"
          icon={iconTrasactions}
          value={totalTransactions}
          textColor={theme.palette.primary.darker}
          backgroundColor={theme.palette.primary.lighter}
          isLoading={isLoading}
          onClick={() => onSummaryClick('total')}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          text="Sincronizadas"
          icon={iconTrasactions}
          value={totalPipedriveSuccess}
          textColor={theme.palette.secondary.darker}
          backgroundColor={theme.palette.secondary.lighter}
          isLoading={isLoading}
          onClick={() => onSummaryClick('synced')}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          text="Aguardando sincronismo"
          icon={iconTrasactions}
          value={totalNotSync}
          textColor={theme.palette.warning.darker}
          backgroundColor={theme.palette.warning.lighter}
          isLoading={isLoading}
          onClick={() => onSummaryClick('waiting')}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SummaryCard
          text="Erro ao sincronizar"
          icon={iconTrasactions}
          value={totalWithErros}
          textColor={theme.palette.error.darker}
          backgroundColor={theme.palette.error.lighter}
          isLoading={isLoading}
          onClick={() => onSummaryClick('error')}
        />
      </Grid>
    </>
  );
};

export default Summary;
