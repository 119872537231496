import { useState, useEffect } from 'react';
import moment from 'moment';
import Axios from 'axios';

// material
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

import { Box, Grid, Container, Typography, LinearProgress } from '@material-ui/core';

// components
import { firestore } from '../firebase';
import Page from '../components/Page';

const PagarmeAPI =
  'https://us-central1-edti-micro-services.cloudfunctions.net/api/pagarme/blacklist';

export default function PagarmeBlackList() {
  const customerCollection = firestore.collection('pagarme_blacklist');
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    customerCollection.get().then((snapshot) => {
      const customersRaw = snapshot.docs
        .map((doc) => doc.data())
        .sort((a, b) => {
          if (!a.blocked && b.blocked) return -1;
          if (a.blocked && !b.blocked) return 1;
          return 0;
        });
      setCustomers(customersRaw);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    { id: '', label: '', minWidth: 100 },
    { id: 'email', label: 'E-mail', minWidth: 300 },
    { id: 'documentNumber', label: 'Documento', minWidth: 300 },
    { id: 'date', label: 'Data do bloqueio', minWidth: 300 }
  ];

  const rows = customers.map((customer) => ({
    id: customer.document_number,
    blocked: customer.blocked,
    email: customer.email,
    documentNumber: customer.document_number,
    date: moment(customer.create_at).format('DD/MM/YY')
  }));

  const handleChange = async (customer) => {
    try {
      if (customer.blocked) {
        await Axios.delete(PagarmeAPI, {
          data: { document_number: customer.document_number }
        });
      } else {
        await Axios.post(PagarmeAPI, {
          document_number: customer.document_number
        });
      }

      setCustomers((prevState) => {
        prevState.find((c) => c.document_number === customer.document_number).blocked =
          !customer.blocked;
        return prevState;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function Row(props) {
    const { row } = props;
    return (
      <TableRow>
        <TableCell align="left">
          <Switch
            checked={row.blocked}
            onChange={() => handleChange(row)}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.documentNumber}</TableCell>
        <TableCell align="left">{row.blocked && row.date}</TableCell>
      </TableRow>
    );
  }

  return (
    <Page title="Pagarme | Blacklist">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" style={{ marginBottom: '10px' }}>
            Blacklist Pagar.me
          </Typography>
          {isLoading && <LinearProgress />}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ height: 500, width: '100%' }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <Row key={row.name} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
