import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Typography, Skeleton } from '@material-ui/core';

import { fShortenNumber } from '../../utils/formatNumber';

const SummaryCard = ({ value, text, textColor, backgroundColor, isLoading, onClick }) => (
  <CardStyle {...{ textColor, backgroundColor }} onClick={onClick}>
    <Typography variant="h3" align="center">
      {isLoading ? <Skeleton variant="text" width={100} animation="wave" /> : fShortenNumber(value)}
    </Typography>

    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      {text}
    </Typography>
  </CardStyle>
);

const CardStyle = styled(Card)(({ theme, textColor, backgroundColor }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: textColor || theme.palette.primary.dark,
  backgroundColor: backgroundColor || theme.palette.primary.lighter,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer'
}));

export default SummaryCard;
