import { Icon } from '@iconify/react';
import pieChart from '@iconify/icons-eva/pie-chart-outline';
import lock from '@iconify/icons-eva/lock-outline';
import fileTxt from '@iconify/icons-eva/file-text-outline';
import calendar from '@iconify/icons-eva/calendar-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Calendário de cursos',
    path: '/courses',
    icon: getIcon(calendar)
  },
  {
    title: 'Negócios Pipedrive',
    path: '/dashboard/pipedrive',
    icon: getIcon(pieChart)
  },
  {
    title: 'Ordem de serviço Omie',
    path: '/dashboard/omie',
    icon: getIcon(fileTxt)
  },
  {
    title: 'Blacklist Pagarme',
    path: '/dashboard/pagarme/backlist',
    icon: getIcon(lock)
  }
];

export default sidebarConfig;
